import { KV_MERGE, KV_DELETE } from './actions'

export const initialKV = {}

export const kvReducer = (state, action) => {
  switch (action.type) {
    case KV_MERGE:
      return { ...state, ...action.value }
    case KV_DELETE: {
      const st = { ...state }
      delete st[action.key]
      if (Object.keys(st).length > 0) return st
      else return {}
    }
    default:
      return state
  }
}
