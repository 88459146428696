import React, { createContext, useContext, useReducer } from 'react'
import mainReducer, { initialState } from '../reducers'
export const StateContext = createContext()
import PropTypes from 'prop-types'

export const StoreProvider = ({ reducer, initialState: initState, children }) => (
  <StateContext.Provider value={useReducer(reducer || mainReducer, initState || initialState)}>{children}</StateContext.Provider>
)

StoreProvider.propTypes = {
  reducer: PropTypes.object,
  initialState: PropTypes.object,
  children: PropTypes.any,
}

export const useStore = () => useContext(StateContext)
