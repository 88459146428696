import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { REFERRAL_REGEX, CREW_REGEX } from '../globals'
import { setItem, removeItem } from '../lib/asyncStorage'

//const supressLogging = process.env.NEXT_PUBLIC_SUPPRESS_HOOK_LOGGING === 'true'

export default function useInviteURL() {
  const router = useRouter()
  const query = router.query
  const refer = router.query.r
  const crew = router.query.c

  useEffect(() => {
    const asyncStorage = async () => {
      if (refer && REFERRAL_REGEX.test(refer)) {
        await setItem('refer', refer)
        await removeItem('crew')
      } else if (crew && CREW_REGEX.test(crew)) {
        await setItem('crew', crew)
        await removeItem('refer')
      }
    }
    asyncStorage()
  }, [router.query])

  const setPath = (pathname, clearQuery = false) => {
    router.push({
      pathname,
      query: !clearQuery ? query : {},
    })
  }
  return [query, setPath]
}
